<template>
    <v-container fluid>
        <v-alert
        dense
        outlined
        type="error"
        v-if="Object.keys(validation_errors).length > 0"
        >
            <ul>
                <li v-for="(errors, field) in validation_errors" :key="field">
                    {{ field }}
                    <ul>
                        <li v-for="(error, sl) in errors" :key="sl">
                            {{ error }}
                        </li>
                    </ul>
                </li>
            </ul>
        </v-alert>
        <v-form
            @submit.prevent="saveNotice"
            ref="noticeForm"
            class="custom-form"
        >
            <v-row dense>
                <v-col cols="12" md="5">
                    <v-card :elevation="1">
                        <v-toolbar color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Notice</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="py-2">
                            <v-row>
                                <v-col cols="12" class="py-0">
                                    <v-textarea
                                        outlined
                                        hide-details
                                        :rows="3"
                                        v-model.trim="notice.notice"
                                        :rules="[(v) => !!v]"
                                        :error-messages="validation_errors.notice"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12" class="text-right">
                                    <v-btn
                                        height="30px"
                                        dark
                                        color="light-blue darken-2 mr-1"
                                        :elevation="1"
                                        type="submit"
                                        :loading="loading"
                                    >Save</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="7">
                    <v-card :elevation="1">
                        <v-toolbar color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Notice List</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="py-2">
                            <v-simple-table
                            dense
                            class="record_table"
                            >
                                <tr>
                                    <th>SL.</th>
                                    <th>Date</th>
                                    <th>Notice</th>
                                    <th>Action</th>
                                </tr>
                                <tr v-for="(data, sl) in notices" :key="sl">
                                    <td>{{++sl}}</td>
                                    <td>{{data.created_at | dateFormat}}</td>
                                    <td>{{data.notice}}</td>
                                    <td>
                                        <v-tooltip bottom v-if="$store.state['user'].role != 'User'">
                                            <template v-slot:activator="{ on }">
                                                <v-icon
                                                    small
                                                    @click="editNotice(data)"
                                                    color="primary"
                                                    v-on="on"
                                                    >mdi-circle-edit-outline</v-icon
                                                >
                                            </template>
                                            <span>Edit</span>
                                        </v-tooltip>
                                        <v-tooltip bottom v-if="$store.state['user'].role == 'Admin'">
                                            <template v-slot:activator="{ on }">
                                                <v-icon
                                                    small
                                                    @click="deleteNotice(data.id)"
                                                    color="error"
                                                    v-on="on"
                                                    >mdi-delete-circle-outline</v-icon
                                                >
                                            </template>
                                            <span>Delete</span>
                                        </v-tooltip>
                                    </td>
                                </tr>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import utility from '../../mixins/utility.mixin';
export default {
    name: "Notice",
    mixins: [utility],
    data() {
        return {
            notice: {
                id    : null,
                notice: '',
            },
            notices: [],
            loading: false,
            validation_errors: {},
        }
    },
    created(){
        this.getNotices();
    },
    methods: {
        async getNotices(){
            this.notices = await this.$store.dispatch('notice/getNotices', true);
        },
        async saveNotice(){
            if(!this.$refs.noticeForm.validate()){
                this.$store.dispatch('snackbar/error', 'Fill red marked fields');
                return;
            }

            this.loading = true;

            let url = '/add-notice';
            if(this.notice.id != null){
                url = '/update-notice';
            }

            let data = {
                url, 
                notice: this.notice,
            }

            let resObj = await this.$store.dispatch("notice/saveNotice",  data);

            if(resObj.hasOwnProperty("validation_errors")){
                this.validation_errors = resObj.validation_errors;
            }
            this.loading = false;

            if(resObj.success) {
                this.resetForm();
                this.getNotices();
            }
        },
        resetForm(){
            this.validation_errors = {};

            this.notice = {
                id: null,
                notice: '',
            };

            this.$refs.noticeForm.resetValidation();
        },

        editNotice(notice){
            this.notice = {
                id    : notice.id,
                notice: notice.notice,
            }
        },
        async deleteNotice(id){
            if (!confirm("Are you sure?")) return;

            await this.$store.dispatch("notice/deleteNotice", id);
            this.getNotices();
        }
    },
}
</script>

<style scoped>
.record_table table,
.record_table th,
.record_table td {
    border: 1px solid #dee2e6;
    border-collapse: collapse;
    padding: 0 !important;
}

.record_table td {
  padding: 2px !important;
  font-size: 11px !important;
  text-align: center;
  height: 0 !important;
}

.v-data-table thead{
    background-color: #5df0f096 !important;
}


>>>.v-btn--icon.v-size--x-large{
    height: unset;
    width: unset;
}

>>>.v-dialog > .v-card > .v-card__title{
    padding: unset;
}

.theme--light.v-toolbar.v-sheet {
    background-color: #5df0f096 !important;
}

.v-card__text .caption{
 color: #000;
}


.v-sheet.v-card{
    background: #6ef6f63d !important;
}
</style>